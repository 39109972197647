import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InPageNavigation from "../components/inpage-navigation"
import "../css/custom/program.css"


const ProgramPage = () => (
    <Layout>
        <Seo title="Symposium program" />
        <InPageNavigation />
        <div className="fluid-container">
            <div className="row">

                <article className="cell col-sm-9 mbottom-default">
                    <h1>Program</h1>
                    <p>
                    The number of places at the <Link to="/workshops/">workshops</Link> and <Link to="/roundtables/">roundtable sessions</Link> are limited. So <a href="https://wurtl.ugent.be/c/1/fears2024">reserve your spot now</a>!
                    </p>
                    <div className="row padding-0">
                        <div className="col-sm-12">
                            <a href="https://wurtl.ugent.be/c/1/fears2024" className="btn btn-default"
                               activeClassName="active">Register for FEARS</a>
                        </div>
                    </div>
                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="pre-fears">Pre-FEARS workshops</h2>
                    <div className="row">
                        <div className="col-sm-12 pbottom-default">
                            All info about the pre-FEARS workshops on the <Link to="/workshops">workshop page</Link>.
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-xs-12 pbottom-default">
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="h3"><strike>30 September</strike></p><p>13:30-16:30</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="h3"><strike>Workshop: How to develop an academic poster?</strike> FULL</p>
                                {/*<p className="h3">Workshop: How to develop an academic poster?</p>*/}
                                Prepare for the poster session by following this <Link to="/workshops/#poster">workshop</Link>.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="h3"><strike>3 October</strike></p><p>13:30-16:30</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="h3"><strike>Workshop: How to pitch your research for a broader audience?</strike> FULL</p>
                                Prepare for the pitch session by following this <Link to="/workshops/#pitch">workshop</Link>.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <p className="h3"><strike>17 October</strike></p><p>13:30:30-16:30</p>
                            </div>
                            <div className="col-sm-9">
                                <p className="h3"><strike>Workshop: Questions: how to ask them and how to answer them in a clear, concise and confident way.</strike> FULL</p>
                                Prepare for the industry roundtable session by following this <Link to="/workshops/#questions">workshop</Link>.
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="pbottom-default" style={{ borderTop: "1px solid #ccc" }}></div>
                    <h2 id="program">FEARS 2024 program</h2>

                    <div className="row">
                        <div className="col-xs-12 pbottom-default">
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">13:00</p>
                                </div>
                                <div className="col-sm-10">
                                    <h3>Registration</h3>
                                    <p>All attendees are welcome from 1pm to enjoy a coffee. Presenters can install
                                        their demo setups and posters.</p>
                                </div>
                            </div>
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">13:30</p>(Parallel sessions)
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <h3>Poster and demo session (Part 1)</h3>
                                            <p>13:15-14:45</p>
                                            <p>Stroll through interesting posters and demonstrations of FEA research and
                                                take the time to get to know colleagues.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Submit your poster or demo</a></em>
                                                <br></br><em><a href="#poster">Detailed poster and demo
                                                    schedule</a></em></p>
                                        </div>
                                        <div className="col-sm-4">
                                            <h3>Pitch session</h3>
                                            <p>13:30-14:45</p>
                                            <p>Get inspired by a selection of two-minute pitches by FEA researchers.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Submit your pitch</a></em>
                                                <br></br><em><a href="#pitch">Detailed pitch schedule</a></em>
                                            </p>
                                        </div>
                                        <div className="col-sm-4">
                                            <h3>Workshop 1: Your PhD in a CV</h3>
                                            <p>13:30-14:30</p>
                                            <p>Get to know the do’s and don’ts for drafting a cv with an academic
                                                background.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Register your spot at the workshop</a></em>
                                                <br></br><em><Link to="/workshops/#cv">More info on the workshops
                                                    page</Link></em>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">15:00</p>
                                </div>
                                <div className="col-sm-10">
                                    <p className="h3">Coffee break</p>
                                </div>
                            </div>
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">15:30</p>(Parallel sessions)
                                </div>
                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h3>Poster and demo session (Part 2)</h3>
                                            <p>15:30-17:00</p>
                                            <p>Stroll through interesting posters and demonstrations of FEA research and
                                                take the time to get to know colleagues.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Submit your poster or demo</a></em>
                                                <br></br><em><a href="#poster">Detailed poster and demo
                                                    schedule</a></em></p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Roundtable session</h3>
                                            <p>15:45-16:30</p>
                                            <p>
                                                Dive deep into hot topics in engineering and architecture with leading
                                                companies at our Industry Roundtables.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Register your spot at the table</a></em>
                                                <br></br><em><Link to="/roundtables/">More info on the roundtable
                                                page</Link></em>
                                            </p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Workshop 2: Pursuing a doctoral degree</h3>
                                            <p>15:30-16:30</p>
                                            <p>
                                                Information session for students. We help you figure out whether
                                                a PhD is something for you and how you can start one.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Register your spot at the workshop</a></em>
                                                <br></br><em><Link to="/workshops/#starting-phd">More info on the workshops
                                                page</Link></em>
                                            </p>
                                        </div>
                                        <div className="col-sm-3">
                                            <h3>Workshop 3: Funding for entrepreneurial postdocs</h3>
                                            <p>15:30-16:30</p>
                                            <p>
                                                An introduction to the different TechTransfer practices for
                                                postdocs and finalising PhD researchers.
                                                <br></br><em><a href="https://wurtl.ugent.be/c/1/fears2024">Register your spot at the workshop</a></em>
                                                <br></br><em><Link to="/workshops/#valorization">More info on the workshops
                                                page</Link></em>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Btw can we also change this a bit? I keep receiving questions for sponsors, I would put first pitches from companies (vanaf 17:00 voor 25 min) dan  Panel AI& ethics (45 min) en dan award ceremony with reception (tot 20u) */}
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">17:00 </p>
                                </div>
                                <div className="col-sm-10">
                                    <h3>Pitches from sponsors</h3>
                                    <p>
                                        In this session, the sponsoring companies take the stage
                                        to deliver pitches about who they are and what they do.
                                        This is a unique opportunity to hear directly from industry
                                        leaders and emerging startups as they present their products,
                                        services, and solutions.
                                    </p>
                                </div>
                            </div>
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">17:25 </p>
                                </div>
                                <div className="col-sm-10">
                                    <h3>Panel AI and Ethics</h3>
                                    <p>
                                        A panel discussion on "AI and Ethics", delving into the crucial topics of
                                        ethics, fairness, and transparency in artificial intelligence, with a focus on
                                        the implications of regulations like the EU AI Act.
                                        <br />
                                        Panel members: <a href="dataroots.io">Dataroots</a>, <a href="ml6.eu">ML6</a>, <a href="techwolf.com">Techwolf</a>
                                        {/*Confirmed members: prof. Eric Mannens (UGent), prof. Lieve De Wachter
                                        (KULeuven), Matthias Feys, PhD (CTO ML6) and Laurent Sorber (CTO Radix.ai).*/}
                                    </p>
                                </div>
                            </div>
                            <div className="row pbottom-default">
                                <div className="col-sm-2">
                                    <p className="h3">18:10 - 20:00</p>
                                </div>
                                <div className="col-sm-10">
                                    <h3>Award ceremony and reception</h3>
                                    <p>We will close FEARS 2024 with a drink and some bites, while presenting <Link
                                        to="/awards/">awards</Link> for remarkable contributions to the symposium.</p>
                                </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-sm-3">
                                <p className="h3">19:00 - 0:00 </p>
                            </div>
                            <div className="col-sm-9">
                                <p className="h3">After party in the city center.</p>
                                <p>After-work party in collaboration with <a href="https://aig.ugent.be/">AIG - Engage</a>. Enjoy the evening with your colleages at Bar Des Amis and continue the scientific discussion.
                                More information on the AIG - Engage <a href="https://aig.ugent.be/event-2022/engage-after-work/" target="_blank" rel="noopener noreferrer">event page</a>.</p>
                                <a href="https://aig.ugent.be/event-2022/engage-after-work/" target="_blank" rel="noopener noreferrer"><img width={680} src={engage} alt="engage after-work"></img></a>
                            </div>
                        </div> */}
                        </div>
                    </div>

                    <div className="pbottom-default" style={{ borderBottom: "1px solid #ccc" }}></div>
                    <h2 id="pitch">Pitch sessions</h2>
                    <div className="row equal">
                        <div className="col-sm-12">
                            <p>
                                Pitch sessions are organized in the <strong>Calefactory</strong>.&nbsp;
                                {/*Please find the order of pitches below.*/}
                                As we get closer to the event, you will find the order of the pitches below.
                            </p>
                        </div>

                        {/*pitches.map(pitch => (
                            <div className="col-sm-6 col-md-4">
                                <PitchEntry pitch={pitch} />
                            </div>
                        ))*/}
                    </div>

                    <div className="pbottom-default"
                         style={{ borderBottom: "1px solid #ccc", paddingTop: "32px" }}></div>
                    <h2 id="poster">Poster and demo sessions</h2>
                    <div className="row equal">
                        <div className="col-sm-12">
                            <p>
                                Poster and demo sessions are organized in the <strong>Chapter Room</strong>.&nbsp;
                                {/*You can find the poster IDs below.*/}
                                As we get closer to the event, you will find the poster IDs below.
                            </p>
                        </div>

                        {/* TODO:: copy paste the JSON out of WURTL accepted submissions here in a posters varianble and unquote the posters mapping underneath */}
                        {/*posters.map(poster => (
                            <div className="col-sm-6 col-md-4">
                                <PosterEntry poster={poster} />
                            </div>
                        ))*/}
                    </div>


                </article>
            </div>

        </div>
    </Layout>
)

export default ProgramPage
